<template>
  <van-empty image="error" description="页面不见啦" />
</template>

<script>
import { Empty } from 'vant';

export default {
  name: 'NotFound',

  components: {
    [Empty.name]: Empty,
  },
};
</script>
